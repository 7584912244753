<template>
  <Treeselect
    v-model="organizational_structure_id"
    placeholder="Selecione um item da esturtura organizacional..."
    :options="options"
  />
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions } from "vuex";

export default {
  components: { Treeselect },
  props: {
    value: {
      type: Number,
    },
    disabled_node: {
      type: Number,
    },
  },
  data: () => ({
    organizational_structure_id: null,
    organizational_structures: [],
  }),
  computed: {
    options() {
      var recursive_data = (id) => {
        var level = [];
        this.organizational_structures.forEach((node) => {
          if (id == node.parent_id) {
            var item = {
              id: node.id,
              label: node.name,
              isDisabled: this.disabled_node == node.id
            };

            if (node.children.length) {
              item.children = recursive_data(node.id);
            }
            level.push(item);
          }
        });
        return level;
      };

      return recursive_data(null);
    },
  },
  methods: {
    ...mapActions("organizational_structures", ["ActionFind"]),
  },
  watch: {
    organizational_structure_id: function (value) {
      this.$emit("input", value ? value : null);
    },
  },
  async created() {
    this.organizational_structures = await this.ActionFind("");
    this.organizational_structure_id = this.value ? this.value : null;
  },
};
</script>

<style>
</style>
