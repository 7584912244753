<template>
  <VueTypeaheadBootstrap
    v-model="search"
    :data="responsibles"
    :serializer="(item) => (item ? item.name : null)"
    :placeholder="placeholder"
    @input="findResponsible"
    @hit="responsible = $event"
    class="form-control form-control-custom"
    :class="[stateClass]"
  >
  </VueTypeaheadBootstrap>
</template>

<script>
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { mapActions } from "vuex";
import { debounce } from "lodash";

import {
  formControlMixin,
  props as formControlProps,
} from "bootstrap-vue/esm/mixins/form-control";

import {
  formStateMixin,
  props as formStateProps,
} from "bootstrap-vue/esm/mixins/form-state";

import { sortKeys } from "bootstrap-vue/esm/utils/object";
import { makePropsConfigurable } from "bootstrap-vue/esm/utils/props";

const props = makePropsConfigurable(
  sortKeys({
    ...formStateProps,
    ...formControlProps,
    value: {},
    placeholder: {
      type: String,
      default: "Buscar responsável ...",
    },
  })
);

export default {
  components: { VueTypeaheadBootstrap },
  mixins: [formControlMixin, formStateMixin],
  props,
  data: () => ({
    responsible: null,
    search: "",
    responsibles: [],
  }),
  computed: {},
  methods: {
    ...mapActions("physical_people", ["ActionFind", "ActionFindById"]),
    findResponsible: debounce(async function () {
      var responsibles = await this.ActionFind(this.search);
      this.responsibles = responsibles;
    }, 500),
  },
  watch: {
    search: function (value) {
      if (value === "") {
        this.responsible = null;
      }
    },
    responsible: function () {
      if (this.responsible) {
        this.search = this.responsible.name;
        this.responsibles = [this.responsible];
        this.$emit("input", this.responsible.id);
      } else {
        this.$emit("input", null);
      }
    },
  },
  async created() {
    if (this.value) {
      const responsible = await this.ActionFindById(this.value);
      if (responsible && responsible.id) {
        this.responsible = responsible;
      }
    }
  },
};
</script>

<style lang="scss">
.form-control-custom {
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;

  .form-control {
    border: none !important;
    background: transparent !important;
  }
}
</style>
